import Section from '../Section';
import SectionHeader from '../SectionHeader';
import { Tit } from '../Titles';
import Obj from '@/assets/img/advocacy-for-children/obj1@2x.png';
import Image from '@/components/Image';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const SectionObj = styled(Section)`
  .flex-wrap {
    align-items: flex-end;
  }

  ${SectionHeader} {
    width: 48.2%;
    position: relative;
    padding-bottom: 0;

    &::before {
      content: '';
      width: 580px;
      height: 544px;
      background: url(${Obj}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: -230px;
      top: -117px;
      z-index: -1;
    }
    ${breakpoint(1160)} {
      &::before {
        width: 50vw;
        height: 46.9vw;
        left: -19.83vw;
        top: -10.09vw;
      }
    }
  }

  .col-img {
    width: 51.8%;
  }

  .article-container {
    padding-top: 240px;

    & > article {
      margin-bottom: 140px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader},
    .col-img {
      width: 100%;
    }
    .col-img {
      margin-top: 8px;
    }

    .article-container {
      padding-top: 120px;
      & > article {
        margin-bottom: 120px;
      }
    }
  }
`;

export const NoList = styled.ul`
  & > li {
    position: relative;
    padding-left: 42px;
    margin-bottom: 16px;
    line-height: 2;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      background: #1cabe2;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 5px;
    }

    &:nth-child(1) {
      &::before {
        content: '1';
      }
    }
    &:nth-child(2) {
      &::before {
        content: '2';
      }
    }
    &:nth-child(3) {
      &::before {
        content: '3';
      }
    }
    &:nth-child(4) {
      &::before {
        content: '4';
      }
    }
    &:nth-child(5) {
      &::before {
        content: '5';
      }
    }
    &:nth-child(6) {
      &::before {
        content: '6';
      }
    }
    &:nth-child(7) {
      &::before {
        content: '7';
      }
    }
    &:nth-child(8) {
      &::before {
        content: '8';
      }
    }
    &:nth-child(9) {
      &::before {
        content: '9';
      }
    }
    &:nth-child(10) {
      &::before {
        content: '10';
      }
    }
  }

  ${breakpoint(`tablet`)} {
    & > li {
      &::before {
        top: 3px;
      }
    }
  }
`;

export const ObjList = styled.ul`
  align-items: stretch;

  & > li {
    text-align: center;
    width: 20%;
    margin-bottom: 96px;

    ${Image} {
      display: block;
      max-width: 160px;
      margin: 0 auto !important;
    }
    .dec-wrap {
      margin-top: 16px;
      .no {
        color: #1cabe2;
        line-height: 2;
        display: block;
      }
      small {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    & > li {
      margin-bottom: 72px;

      ${Image} {
        max-width: 80px;
      }
      .dec-wrap {
        margin-top: 8px;

        small {
          font-size: 14px;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    & > li {
      width: 50%;
    }
  }
`;

export const CardFlex = styled.ul`
  align-items: stretch;
  & > li {
    margin-bottom: 48px;
    width: 50%;
    .card {
      height: 100%;
      padding: 72px 33px 64px;
      border-radius: 20px;
      box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
      background-color: #ffffff;
      position: relative;

      ${Tit} {
        padding: 8px 32px;
        border-radius: 0 0 16px 0;
        background-color: #1cabe2;
        position: absolute;
        left: 0;
        top: -16px;
      }
    }

    .box {
      height: 100%;
      padding: 24px;
      border-radius: 20px;
      box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
      background-color: #ffffff;
      position: relative;
    }
  }
  ${breakpoint(`mobile`)} {
    & > li {
      margin-bottom: 32px;
      width: 100%;
      padding: 3px;
      .card {
        padding: 56px 25px;

        ${Tit} {
          padding: 6px 16px;
          top: -8px;
        }
      }
    }
  }
`;

export const DlBox = styled.dl`
  margin-top: 64px;
  dt {
    background: #e2f0f6;
    padding: 25px 32px;
  }
  dd {
    padding: 32px;
    border-bottom: 1px solid #828385;
    .cnt-list {
      line-height: 2;
    }
  }

  ${breakpoint(`tablet`)} {
    margin-top: 48px;
    dt {
      padding: 17px 24px;
    }
    dd {
      padding: 24px;
    }
  }
`;
