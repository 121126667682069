import LinkSafe from './LinkSafe';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ItemProps {
  itemData: DataItem[];
}

export interface DataItem {
  id: string | number;
  tit: string;
  desc?: string;
  costumer: string;
  tel?: string | number;
  email?: string;
}

const ContactContainer = styled.div`
  article {
    margin-bottom: 98px;

    &:last-child {
      margin-bottom: 0;
    }

    .header {
      padding-bottom: 32px;
    }
    .description {
      padding-bottom: 50px;
      line-height: 1.7;
    }
    .flex {
      dl {
        width: 33.33%;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    article {
      display: flex;
      margin-bottom: 72px;
      &.mobileBlock {
        display: block;
        .header {
          width: 100%;
          padding-bottom: 24px;
          strong {
            font-size: 16px;
          }
        }
        .description {
          padding-bottom: 32px;
        }
        .flex {
          padding: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .header {
        width: 80px;
      }
      .flex {
        padding-top: 5px;
        width: calc(100% - 80px);
        flex-wrap: wrap;
        padding-left: 30px;

        dl {
          width: 100%;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const ContactDl = styled.dl`
  dt {
    position: relative;
    padding-left: 14px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;

    &::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background: #1cabe2;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 7px;
    }
    ${breakpoint(640)} {
      &::before {
        width: 4px;
        height: 4px;
      }
    }
  }

  dd {
    padding-left: 14px;
    line-height: 1.2;

    .link {
      color: #1cabe2;
      font-weight: 700;
      text-decoration: underline;
    }
  }
`;

const ContactUs: FC<ItemProps> = ({ itemData, ...props }) => (
  <ContactContainer>
    {itemData.map((row) =>
      row.desc ? (
        <article key={row.id} className="mobileBlock">
          <div className="header">
            <Tit size="s3" color="sky">
              {row.tit}
            </Tit>
          </div>
          {row.desc && <div className="description">{row.desc}</div>}
          <div className="flex">
            <ContactDl>
              <dt>담당팀</dt>
              <dd>{row.costumer}</dd>
            </ContactDl>
            {row.tel && (
              <ContactDl>
                <dt>전화</dt>
                <dd>
                  <LinkSafe to={`tel:${row.tel}`}>{row.tel}</LinkSafe>
                </dd>
              </ContactDl>
            )}

            <ContactDl>
              <dt>이메일</dt>
              <dd>
                <LinkSafe to={`mailto:${row.email}`} className="link">
                  {row.email}
                </LinkSafe>
              </dd>
            </ContactDl>
          </div>
        </article>
      ) : (
        <article key={row.id}>
          <div className="header">
            <Tit size="s3" color="sky">
              {row.tit}
            </Tit>
          </div>
          <div className="flex">
            <ContactDl>
              <dt>담당팀</dt>
              <dd>{row.costumer}</dd>
            </ContactDl>
            {row.tel && (
              <ContactDl>
                <dt>전화</dt>
                <dd>
                  <LinkSafe to={`tel:${row.tel}`}>{row.tel}</LinkSafe>
                </dd>
              </ContactDl>
            )}
            <ContactDl>
              <dt>이메일</dt>
              <dd>
                <LinkSafe to={`mailto:${row.email}`} className="link">
                  {row.email}
                </LinkSafe>
              </dd>
            </ContactDl>
          </div>
        </article>
      ),
    )}
  </ContactContainer>
);
export default ContactUs;
